import { COLORS } from '../colors/colors'
import { Controller } from '@hotwired/stimulus'
import CookieLanguageHelper from '../helpers/cookie_language_helper'

export default class extends Controller {
  static values = { data: Object }

  connect () {
    CookieLanguageHelper.setChartLanguage()

    const rankingData = this.dataValue.ranking_data
    let sorted_keys = Object.keys(rankingData).sort((a, b) => {
      return rankingData[a].ranking - rankingData[b].ranking
    }).reverse()

    const sortedData = sorted_keys.map((key) => {
      return rankingData[key]
    })

    const rankingChartConfig = {
      type: 'doughnut',
      data: {
        labels: sortedData.map(d => d.title),
        datasets: [{
          data: sortedData.map(d => d.ranking),
          backgroundColor: COLORS.map(c => c.backgroundColor)
        }]
      },
      options: {
        legend: {
          position: 'right',
          fullWidth: false
        },
        maintainAspectRatio: false,
        responsiveAnimationDuration: 1000,
        tooltips: {
          mode: 'nearest',
          intersect: false,
          footerFontStyle: 'normal',
          callbacks: {
            title: (tooltipItems, data) => data.labels[tooltipItems[0].index],
            label: (tooltipItems, data) => {
              const val = Math.round(data.datasets[0].data[tooltipItems.index] * 100) / 100
              return `${val.toLocaleString()}%`
            }
          }
        }
      }
    }
    const rankingChart = this.element.getContext('2d')
    new Chart(rankingChart, rankingChartConfig)

    // $('.height-chart').height($('.ibox').height());
    // $('.content-chart').height($('.ibox-content').height());
  }
}
