import { Controller } from '@hotwired/stimulus'
import DownloadCsvFromArray from '../helpers/download_csv_from_array'
import CookieLanguageHelper from '../helpers/cookie_language_helper'
import moment from 'moment/moment'
import numeral from 'numeral'

export default class extends Controller {
  static values = { data: Object }

  connect () {
    CookieLanguageHelper.setChartLanguage()

    const last_year_reads = this.dataValue.last_year_reads
    const data = Object.values(last_year_reads)
    const labels = Object.keys(last_year_reads)
    const chartConfig = {
      type: 'bar',
      data: {
        labels: labels.map(d => {
          let [month, year] = d.split('-')
          let formattedDate = `${year}-${month}`
          const date = moment(new Date(formattedDate))

          return `${date.format('MMM')} ${date.format('Y')}`
        }),
        datasets: [{
          data: data,
          borderWidth: 1,
          backgroundColor: '#a1afff'
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return numeral(value).format('0,0')
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }

    const lastYearChart = this.element.getContext('2d')
    this.chart = new Chart(lastYearChart, chartConfig)

    $('#download-yearly-data').click(function () {
      window.open(DownloadCsvFromArray.downloadableCSV(Object.entries(last_year_reads), i18n.t('charts.last_year_chart'), [i18n.t('time.date'), i18n.t('reports.leaflets.attributes.reads')]))
    })
  }

  disconnect () {
    super.disconnect()
    this.chart.destroy()
  }
}
