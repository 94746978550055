import { Controller } from '@hotwired/stimulus'
import CookieLanguageHelper from '../helpers/cookie_language_helper'

export default class extends Controller {
  static targets = ["labelInput"]

  connect () {
    document.addEventListener('turbo:load', function() {
      document.querySelectorAll('input[type="text"]').forEach(function(input) {
        const value = input.getAttribute('value');
        if (value) {
          input.value = value;
        }
      });
    });

    $('.leaflets-table').DataTable({
      language: {
        url: CookieLanguageHelper.getTableTranslationFile()
      },
      paging: false,
      searching: false,
      'bInfo': false,
      'columnDefs': [
        { 'orderSequence': ['desc', 'asc'], 'targets': '_all' },
        { 'type': 'num', targets: 6 },
        { 'type': 'numeric-comma', targets: 5 }
      ]
    })
  }
}
