export const COLORS = [
  {
    'backgroundColor': 'rgba(83,182,195,0.5)',
    'borderColor': 'rgba(83,182,195,0.7)',
    'pointBackgroundColor': 'rgba(83,182,195,1)'
  },
  {
    'backgroundColor': 'rgba(0,157,224,0.5)',
    'borderColor': 'rgba(0,157,224,0.7)',
    'pointBackgroundColor': 'rgba(0,157,224,1)'
  },
  {
    'backgroundColor': 'rgba(0,255,168,0.5)',
    'borderColor': 'rgba(0,255,168,0.7)',
    'pointBackgroundColor': 'rgba(0,255,168,1)'
  },
  {
    'backgroundColor': 'rgba(255,215,14,0.5)',
    'borderColor': 'rgba(255,215,14,0.7)',
    'pointBackgroundColor': 'rgba(255,215,14,1)'
  },
  {
    'backgroundColor': 'rgba(255,120,60,0.5)',
    'borderColor': 'rgba(255,120,60,0.7)',
    'pointBackgroundColor': 'rgba(255,120,60,1)'
  },
  {
    'backgroundColor': 'rgba(0,160,176,0.5)',
    'borderColor': 'rgba(0,160,176,0.7)',
    'pointBackgroundColor': 'rgba(0,160,176,1)'
  },
  {
    'backgroundColor': 'rgba(204,51,63,0.5)',
    'borderColor': 'rgba(204,51,63,0.7)',
    'pointBackgroundColor': 'rgba(204,51,63,1)'
  },
  {
    'backgroundColor': 'rgba(153,0,153,0.5)',
    'borderColor': 'rgba(153,0,153,0.7)',
    'pointBackgroundColor': 'rgba(153,0,153,1)'
  },
  {
    'backgroundColor': 'rgba(220,57,18,0.5)',
    'borderColor': 'rgba(220,57,18,0.7)',
    'pointBackgroundColor': 'rgba(220,57,18,1)'
  },
  {
    'backgroundColor': 'rgba(237,201,81,0.5)',
    'borderColor': 'rgba(237,201,81,0.7)',
    'pointBackgroundColor': 'rgba(237,201,81,1)'
  },
  {
    'backgroundColor': 'rgba(51,102,204,0.5)',
    'borderColor': 'rgba(51,102,204,0.7)',
    'pointBackgroundColor': 'rgba(51,102,204,1)'
  },
  {
    'backgroundColor': 'rgba(255,153,0,0.5)',
    'borderColor': 'rgba(255,153,0,0.7)',
    'pointBackgroundColor': 'rgba(255,153,0,1)'
  },
  {
    'backgroundColor': 'rgba(235,104,65,0.5)',
    'borderColor': 'rgba(235,104,65,0.7)',
    'pointBackgroundColor': 'rgba(235,104,65,1)'
  },
  {
    'backgroundColor': 'rgba(16,150,24,0.5)',
    'borderColor': 'rgba(16,150,24,0.7)',
    'pointBackgroundColor': 'rgba(16,150,24,1)'
  },
  {
    'backgroundColor': 'rgba(59,62,172,0.5)',
    'borderColor': 'rgba(59,62,172,0.7)',
    'pointBackgroundColor': 'rgba(59,62,172,1)'
  },
  {
    'backgroundColor': 'rgba(0,153,198,0.5)',
    'borderColor': 'rgba(0,153,198,0.7)',
    'pointBackgroundColor': 'rgba(0,153,198,1)'
  },
  {
    'backgroundColor': 'rgba(221,68,119,0.5)',
    'borderColor': 'rgba(221,68,119,0.7)',
    'pointBackgroundColor': 'rgba(221,68,119,1)'
  },
  {
    'backgroundColor': 'rgba(102,170,0,0.5)',
    'borderColor': 'rgba(102,170,0,0.7)',
    'pointBackgroundColor': 'rgba(102,170,0,1)'
  },
  {
    'backgroundColor': 'rgba(184,46,46,0.5)',
    'borderColor': 'rgba(184,46,46,0.7)',
    'pointBackgroundColor': 'rgba(184,46,46,1)'
  },
  {
    'backgroundColor': 'rgba(49,99,149,0.5)',
    'borderColor': 'rgba(49,99,149,0.7)',
    'pointBackgroundColor': 'rgba(49,99,149,1)'
  },
  {
    'backgroundColor': 'rgba(153,68,153,0.5)',
    'borderColor': 'rgba(153,68,153,0.7)',
    'pointBackgroundColor': 'rgba(153,68,153,1)'
  },
  {
    'backgroundColor': 'rgba(34,170,153,0.5)',
    'borderColor': 'rgba(34,170,153,0.7)',
    'pointBackgroundColor': 'rgba(34,170,153,1)'
  },
  {
    'backgroundColor': 'rgba(170,170,17,0.5)',
    'borderColor': 'rgba(170,170,17,0.7)',
    'pointBackgroundColor': 'rgba(170,170,17,1)'
  },
  {
    'backgroundColor': 'rgba(102,51,204,0.5)',
    'borderColor': 'rgba(102,51,204,0.7)',
    'pointBackgroundColor': 'rgba(102,51,204,1)'
  },
  {
    'backgroundColor': 'rgba(230,115,0,0.5)',
    'borderColor': 'rgba(230,115,0,0.7)',
    'pointBackgroundColor': 'rgba(230,115,0,1)'
  },
  {
    'backgroundColor': 'rgba(139,7,7,0.5)',
    'borderColor': 'rgba(139,7,7,0.7)',
    'pointBackgroundColor': 'rgba(139,7,7,1)'
  },
  {
    'backgroundColor': 'rgba(50,146,98,0.5)',
    'borderColor': 'rgba(50,146,98,0.7)',
    'pointBackgroundColor': 'rgba(50,146,98,1)'
  },
  {
    'backgroundColor': 'rgba(85,116,166,0.5)',
    'borderColor': 'rgba(85,116,166,0.7)',
    'pointBackgroundColor': 'rgba(85,116,166,1)'
  },
  {
    'backgroundColor': 'rgba(0,202,255,0.5)',
    'borderColor': 'rgba(0,202,255,0.7)',
    'pointBackgroundColor': 'rgba(0,202,255,1)'
  },
  {
    'backgroundColor': 'rgba(0,182,232,0.5)',
    'borderColor': 'rgba(0,182,232,0.7)',
    'pointBackgroundColor': 'rgba(0,182,232,1)'
  },
  {
    'backgroundColor': 'rgba(0,121,153,0.5)',
    'borderColor': 'rgba(0,121,153,0.7)',
    'pointBackgroundColor': 'rgba(0,121,153,1)'
  },
  {
    'backgroundColor': 'rgba(59,62,172,0.5)',
    'borderColor': 'rgba(59,62,172,0.7)',
    'pointBackgroundColor': 'rgba(59,62,172,1)'
  }
]
