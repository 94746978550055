import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['loader', 'content']

  connect () {
    document.addEventListener('turbo:before-fetch-request', () => {
      this.contentTarget.classList.add('d-none')
      this.loaderTarget.classList.remove('d-none')
    })

    document.addEventListener('turbo:frame-render', () => {
      this.contentTarget.classList.remove('d-none')
      this.loaderTarget.classList.add('d-none')
    })
  }
}
