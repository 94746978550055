import { Controller } from '@hotwired/stimulus'
import CookieLanguageHelper from '../helpers/cookie_language_helper'

export default class extends Controller {
  connect () {
    $('.table').DataTable({
      language: {
        url: CookieLanguageHelper.getTableTranslationFile()
      },
      paging: true,
      'aLengthMenu': [[10, 25, 50, 100, -1], [10, 25, 50, 100, i18n.t('reports.visits.table.all')]],
      order: [[4, 'desc']],
    })
  }
}
