export default class DownloadCsvFromArray {
  static downloadableCSV(rows, filename, headers = []) {
    let content = "data:text/csv;charset=utf-8,";

    if (headers.length > 0) {
      content += headers.join(",") + "\n";
    }

    rows.forEach(function(row) {
      content += row.join(",") + "\n";
    });

    const encodedUri = encodeURI(content);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  }
}
