import { COLORS } from '../colors/colors'
import { Controller } from '@hotwired/stimulus'
import DownloadCsvFromArray from '../helpers/download_csv_from_array'
import moment from 'moment'
import CookieLanguageHelper from '../helpers/cookie_language_helper'
import numeral from 'numeral'

export default class extends Controller {
  static values = { data: Object }

  connect () {
    CookieLanguageHelper.setChartLanguage()

    const dailyDataHash = this.dataValue.daily_data.data
    const dailyData = Object.values(this.dataValue.daily_data.data),
      weekChart = this.element.getContext('2d')

    const weekConfig = {
      type: 'line',
      data: {
        labels: Object.keys(this.dataValue.daily_data.data).map(d => {
          const date = moment(d)
          return `${date.format('DD.MM.YY')} (${date.format('ddd')})`
        }),
        datasets: [
          {
            ...COLORS[0],
            data: dailyData,
            fill: true,
            tension: 0.2,
            backgroundColor: '#ebeeff',
            borderColor: '#8e9dfe',
            pointBackgroundColor: '#ebeeff'
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        animations: {
          x: {
            duration: 1000,
            easing: 'easeInOutCirc'
          },
          y: {
            duration: 1000,
            easing: 'easeInOutCirc'
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return numeral(value).format('0,0')
              }
            }
          },
        },
        plugins: {
          legend: {
            display: false
          }
        },
      }
    }

    this.chart = new Chart(weekChart, weekConfig)

    $('#download-daily-data').click(function () {
      window.open(DownloadCsvFromArray.downloadableCSV(Object.entries(dailyDataHash), i18n.t('charts.daily_chart_csv_filename'), [i18n.t('time.date'), i18n.t('reports.leaflets.attributes.all_reads_leaflet')]))
    })
  }

  disconnect () {
    super.disconnect()
    this.chart.destroy()
  }
}
