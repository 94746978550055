import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.items = this.element.querySelectorAll('li')
    this.removeActiveFromItems()
    this.setActiveItemBasedOnUrl()

    this.items.forEach(item => {
      item.addEventListener('click', (event) => this.toggleActive(event))
    })
  }

  toggleActive (event) {
    this.removeActiveFromItems()
    event.currentTarget.classList.add('active')
    event.currentTarget.classList.add('current-page')
  }

  removeActiveFromItems () {
    this.items.forEach(item => {
      item.classList.remove('active')
      item.classList.remove('current-page')
    })
  }

  setActiveItemBasedOnUrl () {
    const currentUrl = window.location.href
    this.items.forEach(item => {
      const link = item.querySelector('a')
      if (link && link.href === currentUrl) {
        item.classList.add('active')
      }
    })
  }
}
