import { COLORS } from '../colors/colors'
import { Controller } from '@hotwired/stimulus'
import CookieLanguageHelper from '../helpers/cookie_language_helper'

export default class extends Controller {
  static values = { data: Object }

  connect () {
    CookieLanguageHelper.setChartLanguage()

    const viewersChart = this.element.getContext('2d')
    const viewersConfig = {
      type: 'bar',
      data: {
        labels: Object.values(this.dataValue.ranking_data).map(d => d.title),
        datasets: [
          { ...COLORS[0], data: Object.values(this.dataValue.ranking_data).map(d => d.uniq_users) },
          {
            ...COLORS[1], data: Object.values(this.dataValue.ranking_data).map(d => Math.round(d.ranking * 100) / 100),
            type: 'line', yAxisID: 'ranking-scale'
          }
        ]
      },
      options: {
        legend: null,
        scales: {
          yAxes: [{
            display: true,
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'Unikalnych wyświetleń'
            },
            ticks: {
              beginAtZero: true,
            }
          }, {
            id: 'ranking-scale',
            type: 'linear',
            display: false,
            stacked: false,
            scaleLabel: {
              display: false,
              labelString: 'Impuls zakupowy'
            },
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              suggestedMax: 100
            }
          }]
        }
      }
    }
    new Chart(viewersChart, viewersConfig)
  }
}
