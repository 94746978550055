import moment from 'moment'
import cookie from 'cookie'

export default class CookieLanguageHelper {
  static getLocale () {
    let cookies = cookie.parse(`${document.cookie}`)

    return cookies.locale
  }

  static setChartLanguage () {
    let locale = this.getLocale() ?? 'pl-PL'
    moment.locale(locale)
  }

  static getTableTranslationFile () {
    let locale = this.getLocale()

    let file
    if (locale === 'en') {
      file = '/dataTables.english.json'
    } else {
      file = '/dataTables.polish.json'
    }

    return file
  }
}
