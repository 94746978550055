import { Controller } from '@hotwired/stimulus'
import html2pdf from 'html2pdf.js'

export default class extends Controller {
  connect () {
    document.getElementById('download-pdf').addEventListener('click', function() {
      const element = document.getElementById('pdf-content');

      const elementsToHide = document.querySelectorAll('.no-print');
      elementsToHide.forEach(el => el.classList.add('hidden-in-pdf'));

      const opt = {
        margin: [20, 20, 20, 20],
        filename: i18n.t('reports.summary_pdf'),
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: 'px',
          format: [1250, 1400],
          orientation: 'landscape'
        }
      };

      html2pdf().from(element).set(opt).save().finally(() => {
        elementsToHide.forEach(el => el.classList.remove('hidden-in-pdf'));
      });
    });
  }
}
