import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const archiveForm = document.getElementById('archiveForm')
    const weekPickerInput = document.getElementById('weekPickerInput')
    const monthPickerInput = document.getElementById('monthPickerInput')
    const monthOption = document.getElementById('monthOption')
    const weekOption = document.getElementById('weekOption')
    const archiveButton = document.getElementById('archiveButton')

    monthOption.addEventListener('click', function () {
      archiveForm.classList.remove('d-none')
      archiveButton.innerText = ''
      chooseOption(monthOption)
    })
    weekOption.addEventListener('click', function () {
      archiveForm.classList.remove('d-none')
      archiveButton.innerText = ''
      chooseOption(weekOption)
    })

    let chooseOption = function (type) {
      if (type === monthOption) {
        hideFieldForm(weekPickerInput)
        showFieldForm(monthPickerInput)
      } else {
        hideFieldForm(monthPickerInput)
        showFieldForm(weekPickerInput)
      }
    }

    let showFieldForm = function (field) {
      field.classList.remove('d-none')
    }

    let hideFieldForm = function (field) {
      field.classList.add('d-none')
      field.value = ''
    }
  }
}
