import { Controller } from '@hotwired/stimulus'
import CookieLanguageHelper from '../helpers/cookie_language_helper'

export default class extends Controller {
  static values = { data: Object }

  connect () {
    CookieLanguageHelper.setChartLanguage()

    const demographic_data = this.dataValue.demographic_data
    const date = demographic_data.date
    const labels = ['18-24', '25-34', '35-44', '45-54', '55+']
    const menData = [
      demographic_data.men18to24,
      demographic_data.men25to34,
      demographic_data.men35to44,
      demographic_data.men45to54,
      demographic_data.men55]
    const womenData = [
      demographic_data.women18to24,
      demographic_data.women25to34,
      demographic_data.women35to44,
      demographic_data.women45to54,
      demographic_data.women55]
    const menSum = this.dataValue.demographic_data_men_total
    const womenSum = this.dataValue.demographic_data_women_total
    const total = menSum + womenSum
    const menPercents = menData.map((e) => {
      return to_percent(e, total)
    })
    const womenPercents = womenData.map((e) => {
      return to_percent(e, total)
    })

    const chartConfig = {
      type: 'bar',
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 1,
      data: {
        labels: labels,
        datasets: [{
          label: i18n.t('demographic.men'),
          data: menPercents,
          borderWidth: 1,
          backgroundColor: '#9dabfe',
          borderColor: '#9dabfe'
        },
          {
            label: i18n.t('demographic.women'),
            data: womenPercents,
            borderWidth: 1,
            backgroundColor: '#ebeeff',
            borderColor: '#ebeeff'
          }
        ]
      },
      options: {
        tooltips: {
          callbacks: {
            label: (tooltipItems, data) => {
              let currentValue = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
              return `${currentValue.toLocaleString()}%`
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: i18n.t('demographic.percentage_share'),
            },
            ticks: {
              callback: function (tick) {
                return tick.toString() + '%'
              }
            },
          },
          x: {
            title: {
              display: true,
              text: i18n.t('demographic.age'),
            },
            grid: {
              display: false
            }
          }
        }
      }
    }

    function to_percent (portion, total) {
      return ((portion / total) * 100).toFixed(2)
    }

    const demographicChart = this.element.getContext('2d')
    this.chart = new Chart(demographicChart, chartConfig)
  }

  disconnect () {
    super.disconnect()
    this.chart.destroy()
  }
}
