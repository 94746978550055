import { Controller } from '@hotwired/stimulus'
import CookieLanguageHelper from '../helpers/cookie_language_helper'

export default class extends Controller {

  connect () {
    $('.label-leaflets-table').DataTable({
      language: {
        url: CookieLanguageHelper.getTableTranslationFile()
      },
      paging: false,
      searching: false,
      'bInfo': false,
      'columnDefs': [
        { 'orderSequence': ['desc', 'asc'], 'targets': '_all' },
        { 'type': 'num', targets: 4 },
        { 'type': 'numeric-comma', targets: 5 }
      ]
    })
  }
}
