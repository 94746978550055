import { Controller } from '@hotwired/stimulus'
import CookieLanguageHelper from '../helpers/cookie_language_helper'

export default class extends Controller {
  constructor (context) {
    super(context)
    this.table = null
  }

  connect () {
    this.table = $('.table').DataTable({
      language: {
        url: CookieLanguageHelper.getTableTranslationFile()
      },
      paging: true,
      searching: false,
      sort: true,
      'bInfo': false,
      'aLengthMenu': [[25, 50, -1], [25, 50, 'Wszystkie']],
      'pageLength': 25
    })

    const imagePreview = $('.image-preview')

    imagePreview.mouseenter(function () {
      const imageName = $(this).data('image')
      const imageTag = `<div class="image" style="position:absolute;"><img src="${imageName}" alt="🚫" height="400" /></div>`
      $(this).parent('td').append(imageTag)
    })

    imagePreview.mouseleave(function () {
      $(this).parent('td').children('div.image').remove()
    })
  }

  disconnect () {
    super.disconnect()
    this.table.destroy()
  }
}
