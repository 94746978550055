import { Controller } from '@hotwired/stimulus'
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'

export default class extends Controller {
  connect () {
    new Viewer(document.getElementById('images'), {
      initialCoverage: 1,
      toolbar: {
        zoomIn: true,
        zoomOut: true,
        prev: true,
        next: true
      }
    })
  }
}
