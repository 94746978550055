import { Controller } from '@hotwired/stimulus'
import CookieLanguageHelper from '../helpers/cookie_language_helper'
import { Chart, Legend, Tooltip } from 'chart.js'

Chart.register(Tooltip, Legend)

export default class extends Controller {
  static values = { data: Object }

  connect () {
    CookieLanguageHelper.setChartLanguage()

    const data = {
      labels: [i18n.t('demographic.men'), i18n.t('demographic.women')],
      datasets: [
        {
          data: [this.dataValue.demographic_data_men_total, this.dataValue.demographic_data_women_total],
          backgroundColor: ['#9dabfe', '#ebeeff'],
        }
      ]
    }

    const config = {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          datalabels: {
            display: true,
            backgroundColor: function (context) {
              return context.dataset.backgroundColor
            },
            color: '#6f6f6f',
            font: {
              weight: 'bold'
            },
            padding: 6,
            formatter: function (value, context) {
              let sum = data.datasets[0].data.reduce((acc, cur) => acc + cur, 0)
              let percentage = ((value / sum) * 100).toFixed(2)
              return `${percentage}%`
            },

          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                let sum = data.datasets[0].data.reduce((acc, cur) => acc + cur, 0)
                let value = tooltipItem.raw
                let percentage = ((value / sum) * 100).toFixed(2)
                return `${percentage}%`
              }
            }
          }
        }
      }
    }

    const demographicDoughnutChart = this.element.getContext('2d')
    this.chart = new Chart(demographicDoughnutChart, config)
  }

  disconnect () {
    if (this.chart) {
      this.chart.destroy()
    }
    super.disconnect()
  }
}
